/* src/CuentaPersonal.css */
.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.card {
    width: 900px; /* Aumenta el ancho de la tarjeta */
    height: 330px; /* Aumenta el alto de la tarjeta */
    background: url('./cctemplate.jpg') no-repeat center center;
    background-size: cover;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px; /* Aumenta el padding para el nuevo tamaño */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    transform: scale(1.3); /* Escala todo el contenido de la tarjeta */
}

.card-title {
    text-align: left;
    font-size: 2rem; /* Aumenta el tamaño de la fuente */
    font-weight: bold;
}

.card-number {
    font-size: 2rem; /* Aumenta el tamaño de la fuente */
    letter-spacing: 2px;
    margin: 10px 0;
    text-align: center;
    margin-top: 20px;
}

.card-member-since {
    text-align: right;
    font-size: 1.2rem; /* Aumenta el tamaño de la fuente */
    font-weight: bold;
    margin-top: -10px;
    padding-right: 10px;
    position: relative;
    top: -30px;
}

.card-name {
    text-align: right;
    font-size: 1.5rem; /* Aumenta el tamaño de la fuente */
    font-weight: bold;
    margin-bottom: 10px;
    padding-right: 10px;
    position: relative;
    top: -50px;
}

.card-buttons {
    position: absolute;
    left: 30px; /* Ajusta la posición para el nuevo tamaño */
    top: 80px; /* Ajusta la posición para el nuevo tamaño */
    display: flex;
    flex-direction: column;
}

.card-buttons button {
    margin-bottom: 10px; /* Ajusta el margen para el nuevo tamaño */
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    color: black;
    padding: 10px 15px; /* Aumenta el padding para el nuevo tamaño */
    font-size: 1rem; /* Aumenta el tamaño de la fuente */
}

.card-ecoin {
    position: absolute;
    right: 30px; /* Ajusta la posición para el nuevo tamaño */
    bottom: 30px; /* Ajusta la posición para el nuevo tamaño */
    font-size: 1.2rem; /* Aumenta el tamaño de la fuente */
    background-color: green;
    padding: 10px 15px; /* Aumenta el padding para el nuevo tamaño */
    border-radius: 5px;
    color: white;
}
