/* src/Inscribir.css */
.container {
  margin-top: 20px;
}

.form-control {
  margin-bottom: 10px;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.table {
  margin-top: 20px;
}

.text-center {
  text-align: center;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-left: 0;
  margin-right: 10px;
}
