.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  background-color: #f0f4f8;
}

.login-container h2 {
  font-size: 3rem;
  color: #f8f8f8;
  margin: 20px 0;
  background: linear-gradient(45deg, #ff6b6b, #f8e71c, #50bfa0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: colorChange 4s infinite;
}

@keyframes colorChange {
  0% { color: #ff6b6b; }
  33% { color: #f8e71c; }
  66% { color: #50bfa0; }
  100% { color: #ff6b6b; }
}

.login-container form {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
}

.login-container .login-button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container .login-button:hover {
  background-color: #0056b3;
}

.login-container .error-message {
  color: red;
}
