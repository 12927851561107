/* src/Recursos.css */
.recursos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    background-color: #f0f4f8;
  }
  
  .main-title {
    font-size: 3rem;
    color: #4b6cb7;
    margin-bottom: 20px;
  }
  
  .info-text {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .secondary-title {
    font-size: 2.5rem;
    color: #4b6cb7;
    margin-bottom: 20px;
  }
  
  .content {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .content h3, .content h4 {
    color: #4b6cb7;
  }
  
  .content p, .content ul, .content ol {
    margin-bottom: 20px;
  }
  
  .content ul, .content ol {
    padding-left: 20px;
  }
  
  .content ul li, .content ol li {
    margin-bottom: 10px;
  }
  
  .recursos-container ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .recursos-container ul li {
    margin: 10px 0;
  }
  
  .recursos-container ul li a {
    text-decoration: none;
    color: #007bff;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .recursos-container ul li a:hover {
    color: #0056b3;
  }
  