/* src/CirculosApoyo.css */
.circulos-apoyo-container {
    padding: 20px;
  }
  
  .add-circle, .add-comment {
    margin-bottom: 20px;
  }
  
  .circle-item, .comment-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .members ul {
    list-style-type: none;
    padding: 0;
  }
  
  .responses {
    margin-top: 10px;
  }
  
  .response {
    margin-left: 20px;
  }
  