/* src/Home.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  background-color: #f0f4f8;
}

.home-container h2 {
  font-size: 2.5rem;
  color: #6A5ACD; /* Azul claro más oscuro */
  margin-bottom: 20px;
}

.home-container .user-name {
  color: #8B0000; /* Vino tinto */
}

.modules-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
}

.module {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;
  width: 30%;
}

.module h3 {
  font-size: 1.5rem;
  color: #6A5ACD; /* Azul claro más oscuro */
  margin-bottom: 10px;
}

.module:hover {
  background: rgba(255, 255, 255, 0.2);
}

.module-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.menu-button {
  padding: 15px 25px; /* Aumentar el tamaño de los botones */
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0; /* Más espacio entre los botones */
}

.menu-button:hover {
  background-color: #0056b3;
}

.resources-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #28a745; /* Color diferente para distinguir el botón */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Separación adicional */
}

.resources-button:hover {
  background-color: #218838;
}
