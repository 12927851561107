/* Estilos globales */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9fc;
  color: #333;
}

/* Contenedor principal de la aplicación */
.AppContainer {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

/* Cabecera de la aplicación */
.App-header {
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

/* Botones */
button {
  background-color: #4b6cb7;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a5a99;
}

/* Tabla del CRUD */
.CRUD-table {
  width: 100%;
  border-collapse: collapse;
}

.CRUD-table th,
.CRUD-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.CRUD-table th {
  background-color: #4b6cb7;
  color: white;
}

.CRUD-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.CRUD-table tr:hover {
  background-color: #ddd;
}

.CRUD-table th,
.CRUD-table td {
  text-align: left;
  padding: 12px;
}
